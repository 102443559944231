import React, { useState } from 'react';
import { Avatar, IconButton, Typography, Container, TextField, Box, Button, CircularProgress, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import HandymanIcon from '@mui/icons-material/Handyman';
import StorefrontIcon from '@mui/icons-material/Storefront';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Constants from "../../MeddyConnectConstant";
import MainLayout from "../MainLayout";
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import './homepage.css';

const HomePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authToken, saveSearchedUser, searchedUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [searchNumber, setSearchNumber] = useState('');
  const [countryCode, setCountryCode] = useState("+91");

  const sections = [
    {
      title: 'Account',
      cards: [
        {
          icon: HowToRegIcon,
          title: 'Verification',
          path: Constants.VERIFICATION,
        },
      ],
    },
    {
      title: 'Store',
      cards: [
        {
          icon: CurrencyRupeeIcon,
          title: 'Sell Medical Instrument',
          path: Constants.SELL_PRODUCT,
        },
        {
          icon: HandymanIcon,
          title: 'Repair & Sell Contracts',
          path: Constants.REPAIR_AND_SELL_CONTRACTS,
        },
        {
          icon: StorefrontIcon,
          title: 'Showcase Your Product',
          path: Constants.SHOW_CASE_YOUR_PRODUCT,
        },
      ],
    },
    {
      title: 'Job',
      cards: [
        {
          icon: WorkOutlineIcon,
          title: 'Post a Job',
          path: Constants.POST_A_JOB,
        },
        {
          icon: EditNoteIcon,
          title: 'Manage Jobs',
          path: Constants.MANAGE_JOBS,
        },
      ],
    },
    {
      title: 'Observership',
      cards: [
        {
          icon: WorkOutlineIcon,
          title: 'Post Observership',
          path: Constants.POST_AN_OBSERVERSHIP,
        },
        {
          icon: EditNoteIcon,
          title: 'Manage Observerships',
          path: Constants.MANAGE_OBSERVERSHIPS,
        },
      ],
    },
  ];

  const backgroundColor = theme.palette.mode === 'light' ? '#fff' : '#292929';

  const handleSearch = async () => {
    setLoading(true);

    try {

      const encodedCountryCode = encodeURIComponent(countryCode);

      const apiUrl = `${process.env.REACT_APP_URL}identities/search?countryCode=${encodedCountryCode}&contactNo=${searchNumber}`;

      const response = await axios.get(apiUrl, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authToken}`
        }
      });
     
      // Store user data in context
      const userData = response.data;
      saveSearchedUser(userData);
      toast.success("User details fetched successfully.");

    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/');
      } else {
        toast.error("Error fetching user data:");
      }
    } finally {
      setLoading(false);
  }
  };

  return (
    <MainLayout>
      <Container
        maxWidth="md"
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
          overflowY: 'auto',
          width: '100%',
          p: 1
        }}
      >
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>

            {/* Search Input */}
            <Box
              sx={{
                display: 'flex',
                alignItems: { sm: 'center' },
                justifyContent: { sm: 'center' },
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2,
                mb: 1,
                mt: 1,
                width: '100%',
              }}
            >
              <TextField
                label="Enter Number"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchNumber}
                onChange={(e) => setSearchNumber(e.target.value)}
                sx={{ margin: 0 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                sx={{ textTransform: 'capitalize', fontSize: "16px" }}
              >
                Search
              </Button>
            </Box>

            {Object.keys(searchedUser).length > 0 && (
            <>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>

                 {/* User Info Card  */}
                <Card sx={{ width: '100%', boxShadow: 6 }}>
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on small screens
                      alignItems: 'center',
                      gap: 2,
                      textAlign: { xs: 'center', sm: 'left' }
                    }}
                  >
                    <Avatar
                      src={searchedUser.picture}
                      sx={{ width: 80, height: 80 }}
                    />
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="h6" component="div">
                        {searchedUser.name}
                      </Typography>
                      <Typography variant="body1">
                        User Type: {searchedUser.userType}
                      </Typography>
                      <Typography variant="body1">
                        Mobile: {searchedUser.contactNo}
                      </Typography>
                      <Typography variant="body1">
                        Address: {searchedUser.address}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>

              </Box>
            </>
          )}

            {sections.map((section, index) => (
              <div className="main-Div" key={index}>
                <div className='header-Div'>
                  <Typography align="left" className='main_title'>
                    {section.title}
                  </Typography>
                </div>
                <div className='grid-container' sx={{ backgroundColor }}>
                  {section.cards.map((card, cardIndex) => (
                    <div className="card-box" key={cardIndex}>
                      <Avatar className='Avatar-style'>
                        <IconButton onClick={() => navigate(card.path)}>
                          <card.icon className='icon-style' />
                        </IconButton>
                      </Avatar>
                      <Typography align="center" className='sub_title' color="text.primary">
                        {card.title}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            ))}

          </>
        )}
      </Container>
    </MainLayout>
  )
}

export default HomePage