import React from 'react';
import { CssBaseline } from '@mui/material';
import LoginPage from './components/LoginPage';
import UserDocumentVerification from "./components/UserDocumentVerification";
import HomePage from "./components/HomePage/HomePage";
import PostAJob from "./components/Jobs/PostAJob/PostAJob";
import ManageJobs from "./components/Jobs/ManageJobs/ManageJobs";
import EditPostAJob from "./components/Jobs/ManageJobs/EditPostAJob";
import PostAnObservership from "./components/Observerships/PostAnObservership/PostAnObservership";
import ManageObserverships from "./components/Observerships/ManageObserverships/ManageObserverships";
import EditPostAnObservership from "./components/Observerships/ManageObserverships/EditPostAnObservership";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SellProduct from './components/Marketplace/SellProduct/SellProduct';
import SellContract from './components/Marketplace/SellContract/SellContract';
import ShowCaseProduct from './components/Marketplace/ShowCaseProduct/ShowCaseProduct';

function App() {

  return (
    <AuthProvider>
      <Router>
        <CssBaseline />

         {/* ToastContainer for showing toast notifications globally */}
         <ToastContainer 
          position="bottom-right"
          autoClose={3000} 
          hideProgressBar={false} 
          newestOnTop={false} 
          closeOnClick 
          rtl={false} 
          pauseOnFocusLoss 
          draggable 
          pauseOnHover 
        />
        
        <Routes>

          {/* Public Route */}
          <Route path="/" element={<LoginPage />} />

          {/* Authenticated Routes */}
          <Route path="/documentverification" element={<UserDocumentVerification />} />
          <Route path="/homepage" element={<HomePage />} />
          <Route path="/postajob" element={<PostAJob />} />
          <Route path="/managejobs" element={<ManageJobs />} />
          <Route path="/editpostajob/:jobId" element={<EditPostAJob />} />
          <Route path="/postanobservership" element={<PostAnObservership />} />
          <Route path="/manageobserverships" element={<ManageObserverships />} />
          <Route path="/editpostanobservership/:observershipId" element={<EditPostAnObservership />} />

          <Route path="/sell" element={<SellProduct />} />
          <Route path="/sellContract" element={<SellContract />} />
          <Route path="/showcaseproduct" element={<ShowCaseProduct />} />
        


        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
