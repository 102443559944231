
const Urls = {
    // Login
    GENERATE_TOKEN_URL : "token/generate-token",
    
    REFERENCES_PROFESSIONS_URL : "references/professions",
    REFERENCES_COUNTRIES_URL : "references/countries",
    PRODUCT_CATEGORIES_URL : "references/item-categories",

    // Verification
    UPLOAD_ITEM_IMAGES_URL : "items/upload-image",
    USER_VERIFICATION_DOCUMENT_URL : "identities",
    UPLOAD_ITEM_ATTACHMENTS_URL : "items/upload-attachment",

    // Job and Observership
    JOBS_URL : "jobs",
    OBSERVERSHIP_URL : "internships",
    SELL_URL : "items",
    GET_POSTED_JOBS_BY_USER_URL : "jobs/findActiveByUser",
    GET_POSTED_OBSERVERSHIPS_BY_USER_URL :"internships/findActiveByUser",
    
}

export default Urls