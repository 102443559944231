// Token Related Constants
const Constants = {

    // Pages
    HOME_PAGE: "/homepage",

    // Verification
    VERIFICATION: "/documentverification",

    // Marketplace
    SELL_PRODUCT: "/sell",
    REPAIR_AND_SELL_CONTRACTS: "/sellcontract",
    SHOW_CASE_YOUR_PRODUCT: "/showcaseproduct",

    // Manage Products
    INVENTORY: "/inventory",

    // Job and observership
    POST_A_JOB: "/postajob",
    POST_AN_OBSERVERSHIP: "/postanobservership",
    MANAGE_JOBS: "/managejobs",
    MANAGE_OBSERVERSHIPS: "/manageobserverships",
    EDIT_POST_A_JOB: "/editpostajob",
    EDIT_POST_AN_OBSERVERSHIP: "/editpostanobservership",

}

export default Constants