import React, { useState, useEffect } from 'react';
import { Typography, Container, Box, Button, CircularProgress, Card, CardContent, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import UrlRoute from "../../../API/UrlRoute";
import PlaceIcon from '@mui/icons-material/Place';
import MainLayout from "../../MainLayout";
import { useAuth } from '../../../context/AuthContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import { format, parseISO } from 'date-fns';


const ManageJobs = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authToken, searchedUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const userId = searchedUser?.userId;

  const [postedApplicationsByUser, setPostedApplicationsByUser] = useState([]);

  const getPostedApplicationsByUser = async () => {
    setLoading(true);

    try {

      const apiUrl = `${process.env.REACT_APP_URL}${UrlRoute.GET_POSTED_JOBS_BY_USER_URL}/${userId}`;

      const response = await axios.get(apiUrl, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authToken}`
        }
      });

      setPostedApplicationsByUser(response.data);
      toast.success("Posted jobs fetched successfully..");

    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/');
      } else {
        toast.error("Error fetching posted jobs:");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      getPostedApplicationsByUser();
    }
  }, [userId]);

  const handleEditClick = (id) => {
    navigate(`/editpostajob/${id}`);
  };

  return (
    <>
      <MainLayout>
        <Container
          maxWidth="md"
          disableGutters
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            overflowY: 'auto',
            width: '100%',
            p: 1
          }}
        >
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                {postedApplicationsByUser.length === 0 ? (
                  <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                    No posted jobs available.
                  </Typography>
                ) : (
                  postedApplicationsByUser.map((item) => (
                    <Card sx={{ width: '100%', boxShadow: 6 }} key={item.id}>
                      <CardContent>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="h6" component="div">
                            {item.title}
                          </Typography>
                          <Typography variant="body1">
                            {item.created ? format(parseISO(item.created), "MMM d, yyyy") : ""}
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            <PlaceIcon color="primary" />
                            <Typography variant="body1">
                              {item.city && item.state && item.country ? `${item.city}, ${item.state}, ${item.country}` : item.city || item.state || item.country}
                            </Typography>
                          </Box>
                        </Box>

                        {item.skills.length > 0 && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              margin: '5px 0'
                            }}
                          >
                            {item.skills.map((value, index) => (
                              <Box
                                key={index}
                                sx={{
                                  margin: '0 6px 6px 0',
                                }}
                              >
                                <Chip
                                  label={value}
                                  variant="outlined"
                                  sx={{
                                    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929",
                                    color: theme.palette.mode === "light" ? "#000" : "#fff",
                                  }}
                                />
                              </Box>
                            ))}
                          </Box>
                        )}

                        <Box sx={{ flex: 1 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}>
                            {item.description}
                          </Typography>
                        </Box>
                      </CardContent>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          padding: 2,
                        }}
                      >
                        <Button
                          variant="contained"
                          size="medium"
                          onClick={() => handleEditClick(item.id)}
                        >
                          Edit
                        </Button>
                      </Box>
                    </Card>
                  ))
                )}
              </Box>
            </>
          )}
        </Container>
      </MainLayout>
    </>
  )
}

export default ManageJobs